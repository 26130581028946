import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby"

// We can inject some CSS into the DOM.
const styles = {
  default: {
    background: "#1B232E",
    borderRadius: 50,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    "&:hover": {
      background: "none",
      color: "#1B232E",
      border: "2px solid #1B232E",
    },
  },

  defaultHref: {
    background: "#1B232E !important",
    borderRadius: 50,
    border: "2px solid #1b232e",
    color: "white !important",
    height: 48,
    padding: "11px 30px",
    "&:hover": {
      background: "none !important",
      color: "#1B232E !important",
      border: "2px solid #1B232E !important",
    },
  },

  switchPlansHref: {
    background: "#1B232E !important",
    borderRadius: 50,
    border: "2px solid #1b232e",
    color: "white !important",
    height: 48,
    padding: "11px 30px",
    "&:hover": {
      background: "#f7ce01",
      color: "#1B232E",
    },
  },

  ghost: {
    background: "none",
    borderRadius: 50,
    border: "2px solid #1B232E",
    color: "#1B232E",
    height: 48,
    padding: "0 30px",
    "&:hover": {
      background: "#1B232E",
      color: "white",
      border: 0,
    },
  },

  ghostInvert: {
    background: "none",
    borderRadius: 50,
    border: "2px solid #ffffff",
    color: "#ffffff",
    height: 48,
    padding: "0 30px",
    "&:hover": {
      background: "#f7ce01",
      color: "#1B232E",
      border: "2px solid #f7ce01",
    },
  },

  ghostSubmit: {
    background: "none",
    borderRadius: 50,
    border: "2px solid #1B232E",
    color: "#1B232E",
    height: 48,
    padding: "0 30px",
    "&:hover": {
      background: "#1B232E",
      color: "white",
      border: 0,
    },
  },
}

function navToLink(link) {
  return navigate(link)
}

function ClassNames(props) {
  const { classes, textLabel, linkTo, buttonType, className } = props

  if (buttonType === "ghost") {
    return (
      <Button
        onClick={() => navToLink(linkTo)}
        className={clsx(classes.ghost, className)}
      >
        {textLabel || "Type your label"}
      </Button>
    )
  } else if (buttonType === "default") {
    return (
      <Button
        onClick={() => navToLink(linkTo)}
        className={clsx(classes.default, className)}
      >
        {textLabel || "Type your label"}
      </Button>
    )
  } else if (buttonType === "ghostInvert") {
    return (
      <Button
        onClick={() => navToLink(linkTo)}
        className={clsx(classes.ghostInvert, className)}
      >
        {textLabel || "Type your label"}
      </Button>
    )
  } else if (buttonType === "ghostSubmit") {
    return (
      <Button type="submit" className={clsx(classes.ghostSubmit, className)}>
        {textLabel || "Type your label"}
      </Button>
    )
  } else if (buttonType === "default-href") {
    return (
      <a
        href={linkTo}
        target="_blank"
        className={clsx(classes.defaultHref, className)}
        rel="noopener noreferrer"
      >
        {textLabel || "Type your label"}
      </a>
    )
  } else if (buttonType === "switchplans-href") {
    return (
      <a
        href={linkTo}
        target="_blank"
        className={clsx(classes.switchPlansHref, className)}
        rel="noopener noreferrer"
      >
        {textLabel || "Type your label"}
      </a>
    )
  }
}

ClassNames.propTypes = {
  textLabel: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  buttonType: PropTypes.string,
}

export default withStyles(styles)(ClassNames)
