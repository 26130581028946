import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ButtonVnt from "../components/button-vnt"

const DigitalMarketing = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Mobile-marketing-pana.png" }) {
        base
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Marketing digital" />
      <div className="container page-wrapper">
        <div className="row">
          <div className="col-lg-6">
            <div className="service-img-wrapper">
              <Img
                fluid={data.file.childImageSharp.fluid}
                alt={data.file.base}
              />
            </div>
          </div>

          <div className="col-lg-6 col-xl-5 ml-xl-auto">
            <div className="special_description_content">
              <div className="section_heading">
                <h2 className="title-text">Marketing Digital.</h2>
                <div className={`mb-5 underline_shape`}></div>
              </div>

              <p className="paragpah-text">
                Nos encargamos del branding y la transformación digital para que
                hagas presencia en la web de forma eficiente. ¡El mundo necesita
                saber que existes!.
              </p>
              <p className="paragpah-text">
                Usamos las mejores practicas y herramientas para medir el
                resultado en tiempo real ayudando en la toma de decisiones de
                manera oportuna.
              </p>
            </div>
            <div className="mt-4">
              <ButtonVnt
                linkTo="https://digital.vangtry.com/"
                textLabel="Saber más"
                buttonType="default-href"
              ></ButtonVnt>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DigitalMarketing
